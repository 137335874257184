import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { getHeaderData, HeaderData } from '../lib/header';
function Header({ handleLogoClick }:any) {
  const [isSticky, setIsSticky] = useState(false);

  const [headerData, setHeaderData] = useState<HeaderData | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 80) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
      // Fetch header data function
      const fetchHeaderData = async () => {
        try {
          const data = await getHeaderData();
          setHeaderData(data);
        } catch (error) {
          console.error('Error fetching header data:', error);
        }
      };
  
      fetchHeaderData();

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  
  

  }, []);


  return (
    <header className={isSticky ? 'headerSticky' : ''}>
      <div className="container_wrap">
        <div className="logo">
        {headerData?.headerLogo && (
          <Link href="/" onClick={handleLogoClick} aria-label="logo">
            <Image src={headerData?.headerLogo?.sourceUrl} alt={headerData?.headerLogo?.altText} width={457} height={119} loading="eager"/>
          </Link>
        )}
        </div>
      </div>
    </header>
  );
}

export default Header;
