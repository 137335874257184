import { fetchAPI } from './api';


export interface FooterData {
    footer1: {
      footerLogo: {
        altText: string;
        sourceUrl: string;
      };
      footerDescription: string;
    };
    footer5: {
      ctaButton: {
        title: string;
        url: string;
      };
      title: string;
      address: string;
      contactNumber: string;
      telNumber: string;
      email: string;
    };
    copyright: string;
}
  

export async function getFooterData(): Promise<FooterData> {
    const GET_FOOTER_DATA_QUERY = `
      query GetFooterData {
        themeOptionsFooter {
          footer {
            footer1 {
              footerLogo {
                altText
                sourceUrl
              }
              footerDescription
            }
            footer5 {
              ctaButton {
                title
                url
              }
              title
              address
              contactNumber
              telNumber
              email
            }
            copyright
          }
        }
      }
    `;
  
    try {
      const data = await fetchAPI(GET_FOOTER_DATA_QUERY);
      return data?.themeOptionsFooter?.footer;
    } catch (error) {
      console.error('Error fetching footer data:', error);
      throw new Error('Failed to fetch footer data');
    }
  }
  // Start a Project Section End