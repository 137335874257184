import { fetchAPI } from './api';


export interface HeaderData {
    headerLogo: {
        altText: string;
        sourceUrl: string;
    };
    contactNumber:string;
    telNumber:number;
    getQuote: {
        title: string;
        url: string;
    };
}
  

export async function getHeaderData(): Promise<HeaderData> {
    const GET_HEADER_DATA_QUERY = `
        query GetHeaderData {
            themeOptionsHeader {
                header {
                    headerLogo {
                        altText
                        sourceUrl
                    }
                    contactNumber
                    telNumber
                    getQuote {
                        title
                        url
                    }
                }
            }
        }
    `;
  
    try {
      const data = await fetchAPI(GET_HEADER_DATA_QUERY);
      return data?.themeOptionsHeader?.header;
    } catch (error) {
      console.error('Error fetching header data:', error);
      throw new Error('Failed to fetch header data');
    }
  }
  // Start a Project Section End