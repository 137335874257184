import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { getMenuByName, MenuData  } from '../lib/menus';
import SocialMedia from './comman/SocialMedia';
import { getHeaderData, HeaderData } from '../lib/header';
import { FaBarsStaggered } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { MdCall } from "react-icons/md";
const Sidebar = ({isOpen, toggleSidebar }:any) => {
 
  const [headerSidebarData, setHeaderSidebarData] = useState<HeaderData | null>(null);

  const sidebarRef = useRef<HTMLDivElement>(null);

  // Update the local state when the prop changes
  useEffect(() => {

    const handleOutsideClick = (e: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target as Node)) {
        if (isOpen) {
          toggleSidebar();
        }
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };

  }, [isOpen, toggleSidebar]);

  const closeSidebar = () => {
    if (isOpen) {
      toggleSidebar();
    }
  };


  // get menus code start
  const [mainMenu, setMainMenu] = useState<MenuData | null>(null);
  const MenuName = 'Main Menu';
  useEffect(() => {
    // Get Main Menu Data
    const fetchMenusData = async () => {
      try {
        const menuData = await getMenuByName(MenuName);
        setMainMenu(menuData);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchMenusData();

    const fetchHeaderSideData = async () => {
      try {
        const data = await getHeaderData();
        setHeaderSidebarData(data);
      } catch (error) {
        console.error('Error fetching header data:', error);
      }
    };

    fetchHeaderSideData();

   
  }, [MenuName]);
  

  return (
    <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : ''}`} >
      <button className="toggle-btn"  aria-label="toggle btn" onClick={toggleSidebar}>
       {isOpen ? (
          <IoClose />
        ) : (
          <FaBarsStaggered />
        )}
      </button>
      <div className="sidebar_wrap">
        <div className="side_head">
        {headerSidebarData && (

            <Link href={`tel:${headerSidebarData?.telNumber}`} className='call' onClick={closeSidebar}>
             <MdCall size={20}/>{headerSidebarData?.contactNumber}
              </Link>
        )}
        {headerSidebarData?.getQuote && (
            <Link href={`${headerSidebarData?.getQuote?.url}`} className='cmn_btn_fill' onClick={closeSidebar}>{headerSidebarData?.getQuote?.title}</Link>
        )}
        </div>
        <div className="sidebar_menu">
        
          {mainMenu && (
            <ul>
              {mainMenu.menuItems.nodes.map((menuItem) => (
                <li key={menuItem.id}>
                  <Link href={menuItem?.uri} onClick={closeSidebar}>{menuItem?.label}</Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <SocialMedia className='sidebar_social'/>
      </div>
    </div>
  );
};

export default Sidebar;
