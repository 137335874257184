import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const TopLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleLogoClick = () => {
    if (isSidebarOpen) {
      closeSidebar();
    }
  };

  return (
    <>
      <Header handleLogoClick={handleLogoClick} /> 
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
};

export default TopLayout;
