import { fetchAPI } from './api';

// Recognition Section Start
export async function getRecognitionData() {
    const query = `
    query Recognition {
        themeOptionsInnerpage {
          recognition {
            recognitionSection {
              subTitle
              title
              description
              recognitionButton {
                title
                url
              }
            }
            partenersLogos {
              parteners {
                partenerLogo {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
    }
    `;
  
    try {
      const data = await fetchAPI(query);
      const recognition = data?.themeOptionsInnerpage?.recognition || null;
      
      return recognition;
    } catch (error) {
      console.error('Error fetching recognition data:', error);
      return null;
    }
  }
// Recognition Section End


// Start a Project Section Start
export async function getStartProjectData() {
  const query = `
    query StartProject {
      themeOptionsFooter {
        footer {
          startProject {
            title
            description
            startProjectButton {
              title
              url
            }
            agentExtraLogo {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  `;

  try {
    const data = await fetchAPI(query);
    const startProject = data?.themeOptionsFooter?.footer?.startProject || null;
    
    return startProject;
  } catch (error) {
    console.error('Error fetching startProject data:', error);
    return null;
  }
}
// Start a Project Section End

// Brand Section Start

export async function getBrandLogos(){
  const query = `
  query BrandLogos {
    themeOptionsInnerpage {
      brandLogos {
        brandLogos {
          brandLogo {
            altText
            sourceUrl
          }
        }
      }
    }
  }
  `;

  try {
    const data = await fetchAPI(query);
    const brandLogos = data?.themeOptionsInnerpage?.brandLogos || null;
    
    return brandLogos;
  } catch (error) {
    console.error('Error fetching brand 1 logos data:', error);
    return null;
  }
}
// Brand Section End


export async function GetSocialMedia() {
  const query = `
    query SocialMedia {
      themeOptionsSocialMedia {
        socialMedia {
          mUrl
          twitterUrl
          linkedinUrl
          facebookUrl
          instagramUrl
          whatsAppUrl
        }
      }
    }
  `;

  try {
    const data = await fetchAPI(query);
    const socialMedia = data?.themeOptionsSocialMedia?.socialMedia || null;
    
    return socialMedia;
  } catch (error) {
    console.error('Error fetching Social media data:', error);
    return null;
  }
}
// Brand Section End

 export interface BrandData {
  brandLogos: {
    brandLogo: {
      altText: string;
      sourceUrl: string;
    };
  }[];
  brandTitle: {
    brandTitle: string;
  };
  clientSaysTitle: {
    clientSaysTitle: string;
  };
}

export async function getBrandData() {
  const GET_BRAND_DATA_QUERY = `
    query BrandLogos {
      themeOptionsInnerpage {
        brandLogos {
          brandLogos {
            brandLogo {
              altText
              sourceUrl
            }
          }
        }
        brandTitle {
          brandTitle
        }
        clientSaysTitle {
          clientSaysTitle
        }
      }
    }
  `;

  try {
    const data = await fetchAPI(GET_BRAND_DATA_QUERY);


    const brandLogosData = data?.themeOptionsInnerpage?.brandLogos?.brandLogos || [];
    const brandTitle = data?.themeOptionsInnerpage?.brandTitle?.brandTitle || '';
    const clientSaysTitle = data?.themeOptionsInnerpage?.clientSaysTitle?.clientSaysTitle || '';

 
    const brandLogos = brandLogosData.map((logo: any) => ({
      altText: logo?.brandLogo?.altText || '',
      sourceUrl: logo?.brandLogo?.sourceUrl || ''
    }));

    return {
      brandLogos,
      brandTitle: {
        brandTitle: brandTitle
      },
      clientSaysTitle:{
        clientSaysTitle:clientSaysTitle
      }
    };
  } catch (error) {
    console.error('Error fetching brand 2 data:', error);
    throw new Error('Failed to fetch brand 2 data');
  }
}