import React, { useState, useEffect } from 'react';
import { GetSocialMedia } from '../../lib/comman';
import Link from 'next/link';
import { FaM } from "react-icons/fa6";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
const SocialMedia = ({ className }: { className: string }) => {
  const [socialMediaData, setSocialMediaData] = useState<any>(null);

  useEffect(() => {
    const fetchSocialMediaData = async () => {
      try {
        const data = await GetSocialMedia();
        setSocialMediaData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchSocialMediaData();
  }, []);

  return (
    <>
    {
      socialMediaData && (
        <div className={className}>
          <ul>
          {socialMediaData && socialMediaData?.facebookUrl && (
              <li>
                <Link href={`${socialMediaData?.facebookUrl}`} aria-label="Facebook" target="_blank" rel="noopener">
                  <FaFacebookF />
                </Link>
              </li>
            )}
             {socialMediaData && socialMediaData?.linkedinUrl && (
              <li>
                <Link href={`${socialMediaData?.linkedinUrl}`} aria-label="Linkedin" target="_blank" rel="noopener">
                  <FaLinkedinIn />
                </Link>
              </li>
            )}
            {socialMediaData && socialMediaData?.instagramUrl && (
              <li>
                <Link href={`${socialMediaData?.instagramUrl}`} aria-label="Instagram" target="_blank" rel="noopener">
                  <FaInstagramSquare />
                </Link>
              </li>
            )}
            {socialMediaData && socialMediaData?.mUrl && (
              <li>
                <Link href={`${socialMediaData?.mUrl}`} aria-label="m" target="_blank" rel="noopener">
                  <FaM />
                </Link>
              </li>
            )}
            {socialMediaData && socialMediaData?.twitterUrl && (
              <li>
                <Link href={`${socialMediaData?.twitterUrl}`} aria-label="Twitter" target="_blank" rel="noopener">
                    <BsTwitter />
                </Link>
              </li>
            )}
          </ul>
        </div>
      )
    }  
    </>
  );
};

export default SocialMedia;
