import React, { useEffect, useState } from "react";
import Link from 'next/link'
import Image from 'next/image'
import SocialMedia from "./comman/SocialMedia";
import { MenuData, getMenuByName } from '../lib/menus';
import { getFooterData, FooterData } from '../lib/footer';
function Footer() {
  const currentYear = new Date().getFullYear();
  const [footerData, setFooterData] = useState<FooterData | null>(null);

  const [serviceMenu, setServiceMenu] = useState<MenuData | null>(null);
  const service = 'Services';
  const [insightMenu, setInsightMenu] = useState<MenuData | null>(null);
  const insight = 'Insights';
  const [companyMenu, setCompanyMenu] = useState<MenuData | null>(null);
  const company = 'Company';

  useEffect(() => {
    // Service Menu 
    const fetchServiceMenu = async () => {
      try {
        const serviceData  = await getMenuByName(service);
        setServiceMenu(serviceData );

        const insightData  = await getMenuByName(insight);
        setInsightMenu(insightData );

        const companyData  = await getMenuByName(company);
        setCompanyMenu(companyData);

      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchServiceMenu();

    // get Footer Data
    const fetchFooterData = async () => {
      try {
        const data = await getFooterData();
        setFooterData(data);
      } catch (error) {
        // Handle error or display a message
        console.error('Error:', error);
      }
    };

    fetchFooterData();

 
  }, [service, insight, company]);
  

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_wrap">
          <div className="row">
            <div className="col-xl-3  col-md-6" data-aos="fade" data-aos-delay="100">
              <div className="f1">
                <div className="flogo">
                  {footerData?.footer1?.footerLogo && (
                    <Link href="/">
                      <Image src={footerData?.footer1?.footerLogo?.sourceUrl} alt={footerData?.footer1?.footerLogo?.sourceUrl}  width={273} height={71} loading="lazy"/>
                    </Link>
                  )}
                </div>
                {footerData?.footer1?.footerLogo && (
                  <p >
                    {footerData?.footer1?.footerDescription}
                  </p>
                )}
              
                <SocialMedia className="footer_social"/>
               
              </div>
            </div>

            <div className="col-xl-3  col-md-6" data-aos="fade" data-aos-delay="200">
              <div className="f2">
                {serviceMenu && (
                    <>
                    <h3>{serviceMenu.name}</h3>
                    <div className="f_menus">
                      <ul className="li50">
                        {serviceMenu.menuItems.nodes.map((menuItem) => (
                          <li key={menuItem.id}>
                            <Link href={`${menuItem?.uri}`}>{menuItem?.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    </>
                  )}
              </div>
            </div>
            <div className="col-xl-2 col-md-4" data-aos="fade" data-aos-delay="300">
              <div className="f2">
                {insightMenu && (
                  <>
                  <h3>{insightMenu?.name}</h3>
                  <div className="f_menus">
                    <ul>
                      {insightMenu?.menuItems?.nodes.map((menuItem) => (
                        <li key={menuItem.id}>
                          <Link href={`${menuItem?.uri}`}>{menuItem?.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  </>
                  )}
              </div>
            </div>
            <div className="col-xl-2 col-md-4" data-aos="fade" data-aos-delay="400">
              <div className="f2">
              {companyMenu && (
                <> 
                  <h3>{companyMenu?.name}</h3>
                  <div className="f_menus">
                    <ul>
                      {companyMenu?.menuItems?.nodes.map((menuItem) => (
                        <li key={menuItem.id}>
                          <Link href={`${menuItem?.uri}`}>{menuItem?.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
                  )}
              </div>
            </div>
            <div className="col-xl-2 col-md-4" data-aos="fade" data-aos-delay="500">
              <div className="f2 f3">
                {footerData?.footer5?.ctaButton && (
                  <Link href={footerData?.footer5?.ctaButton?.url} className="cmn_btn">
                    <span>{footerData?.footer5?.ctaButton?.title}</span>
                  </Link>
                )}
                {footerData?.footer5?.title && (
                <h3>{footerData?.footer5?.title}</h3>
                )}
                {footerData?.footer5?.address && (
                  <p dangerouslySetInnerHTML={{ __html: footerData?.footer5.address || '' }}></p>
                )}
                 {footerData?.footer5 && (
                  <Link href={`tel:${footerData?.footer5?.telNumber}`}>{footerData?.footer5?.contactNumber}</Link> 
                 )}
                 <br />
                 {footerData?.footer5?.email && (
                  <Link href={`mailto:${footerData?.footer5?.email}`}>
                    {footerData?.footer5?.email}
                  </Link>
                 )}
              </div>
            </div>
          </div>
        </div>
        {footerData?.copyright && (
        <div className="copy_right">
         <div dangerouslySetInnerHTML={{ __html: footerData?.copyright || '' }}></div>
        </div>
        )}
      </div>
    </footer>
  );
}

export default Footer;

