
import './globals.css';
import { useRouter } from "next/router";
import { useEffect } from "react";
import Footer from '@/components/Footer'
import AOS from 'aos';
import TopLayout from '@/components/TopLayout';

function MyApp({ Component, pageProps}:any) {
  const router = useRouter();
 
  useEffect(() => {
    // Add any global initialization or routing logic here
    require('bootstrap/dist/js/bootstrap.min.js');
    AOS.init({
      duration: 1500
    });
  }, [router]);
  return (
    <>
      <TopLayout/>
        <Component {...pageProps} />
      <Footer/>
    </>


  );
}

export default MyApp;



