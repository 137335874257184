// menu.tsx

import { fetchAPI } from './api'; // Import the fetchAPI function

export interface MenuItem {
    label: string;
    id: string;
    path: string;
    uri: string;
  }
  
  export interface MenuData {
    name: string;
    slug: string;
    menuId: string;
    menuItems: {
      nodes: MenuItem[];
    };
}
// Define the GraphQL query to get menus
export async function getAllMenus(): Promise<MenuData[]> {
    const GET_ALL_MENUS_QUERY = `
      query GetAllMenus {
        menus {
          nodes {
            name
            slug
            menuId
            menuItems {
              nodes {
                label
                id
                path
                uri
              }
            }
          }
        }
      }
    `;
  
    try {
      const data = await fetchAPI(GET_ALL_MENUS_QUERY);
      return data?.menus?.nodes;
    } catch (error) {
      console.error('Error fetching all menus:', error);
      throw new Error('Failed to fetch all menus');
    }
  }

  export async function getMenuByName(name: string): Promise<MenuData | null> {
    const allMenus = await getAllMenus();
    const byName = allMenus.find((menu) => menu.name === name);
    return byName || null;
  }